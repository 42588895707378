<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Scam/Edit</h4>
          <div>
            <router-link :to="{ ...previousRoute }">
              <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
            </router-link>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row">

              <div class="col-12 col-sm-12 col-md-3 border rounded pt-1">

                <div class="col-12">
                  <div class="form-group">
                    <div class="controls">
                      <label>Scam Category <span class="text-danger">*</span></label>
                      <VueMultiselect :allowEmpty="false" v-model="selectedScamCategory" class="multiselect-blue"
                                      :options="scamCategoryOptions" :close-on-select="true"
                                      placeholder="Select Scam Category"
                                      label="name" track-by="value" :show-labels="false"/>
                    </div>
                    <div class="text-danger" v-if="errors.post.postCategoryId">{{ errors.post.postCategoryId }}</div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <div class="controls select-tags-wrapper">
                      <label>Tags (optional)</label>
                      <VueMultiselect v-model="selectedTags" class="multiselect-blue"
                                      placeholder="Select Scam Tags" label="name" track-by="name"
                                      :options="tagOptions"
                                      :close-on-select="true"
                                      :clear-on-select="true"
                                      :multiple="true"
                                      :taggable="true"
                                      :show-labels="false"
                                      :max="4"
                                      :option-height="104"
                                      @tag="createTag"/>
                    </div>
                    <div class="text-danger" v-if="errors.post.postTagId">{{ errors.post.postTagId }}</div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <div class="controls">
                      <label>Is Featured?</label>
                      <div style="margin-top: .5rem">
                        <div
                            class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                          <div>
                            <input v-model="selectedIsFeatured" type="checkbox" class="custom-control-input" checked
                                   id="scamIsFeatured">
                            <label class="custom-control-label mr-1" for="scamIsFeatured"></label>
                          </div>
                          <span class="font-medium-1">{{ selectedIsFeatured ? "Yes" : "No" }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="text-danger" v-if="errors.post.isFeatured">{{ errors.post.isFeatured }}</div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label>Published Date</label>
                    <div class="d-flex align-items-center date-picker-wrapper">
                      <div class="w-100">
                        <DatePicker v-model="putScamData.published_date" :update-on-input="true"
                                    :masks="{ input: ['DD MMMM YYYY'] }"
                                    :model-config="{ type: 'string', mask: 'YYYY-MM-DD' }">
                          <template v-slot="{ inputValue ,togglePopover  }">
                            <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                              <i class='bx bx-calendar mr-1'></i>
                              <input class="form-control" :value="inputValue" style="cursor: pointer;"/>
                            </div>
                          </template>
                        </DatePicker>
                      </div>
                    </div>
                    <div class="text-danger" v-if="errors.post.publishedDate">{{ errors.user.publishedDate }}</div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <div class="controls">
                      <label>Is Published?</label>
                      <div style="margin-top: .5rem">
                        <div
                            class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                          <div>
                            <input v-model="isPublished" type="checkbox" class="custom-control-input" checked
                                   id="postIsPublished">
                            <label class="custom-control-label mr-1" for="postIsPublished"></label>
                          </div>
                          <span class="font-medium-1">{{ isPublished ? "Yes" : "No" }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="text-danger" v-if="errors.post.isPublished">{{ errors.post.isPublished }}</div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <div class="controls">
                      <label>Status</label>
                      <div style="margin-top: .5rem">
                        <div
                            class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                          <div>
                            <input v-model="selectedStatus" type="checkbox" class="custom-control-input" checked
                                   id="scamStatus">
                            <label class="custom-control-label mr-1" for="scamStatus"></label>
                          </div>
                          <span class="font-medium-1">{{ selectedStatus ? "Active" : "Inactive" }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="text-danger" v-if="errors.post.status">{{ errors.post.status }}</div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-8 border rounded ml-2 pt-1">
                <div class="col-12">
                  <div class="form-group">
                    <div>
                      <label for="title">Title *</label>
                      <input v-model="putScamData.title" type="text" class="form-control" id="title"
                             placeholder="Enter Scam Title">
                    </div>
                    <div v-if="errors.post.title" class="text-danger">{{ errors.post.title }}</div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <div>
                      <label for="title">Short Description/ Excerpt</label>
                      <input v-model="putScamData.excerpt" type="text" class="form-control" id="sort description"
                             placeholder="Short Description below 200 words">
                    </div>
                    <div v-if="errors.post.excerpt" class="text-danger">{{ errors.post.excerpt }}</div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group text-editor">
                    <label>Body *</label>
                    <QuillEditor ref="descriptionRef" v-model:content="putScamData.body" contentType="html"
                                 toolbar="full" theme="snow"/>
                    <div class="text-danger" v-if="errors.post.body">{{ errors.post.body }}</div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="accordion" id="accordionExample">
                    <div class="card">
                      <div class="card-header d-flex align-items-center justify-content-between" id="headingOne">
                        <!-- Left half for expanding the accordion -->
                        <div class="flex-grow-1" style="cursor: pointer;" data-toggle="collapse"
                             :title="formatedMeta.length > 0 ? 'Show Meta Information' :'Add Meta Information'"
                             data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          <h5 class="mb-0">Meta Information</h5>
                        </div>

                        <!-- Right half for button action -->
                        <button class="btn ml-2"
                                @click.prevent="toggleModal('metaInformationModal')"
                                :class="[formatedMeta.length > 0 ? 'btn-success' :'btn-danger']">{{ formatedMeta.length > 0 ? 'Update' :'Add' }}</button>
                      </div>

                      <div id="collapseOne" class="collapse hide" aria-labelledby="headingOne"
                           data-parent="#accordionExample">
                        <div class="card-body border">
                          <div v-if="formatedMeta.length > 0">
                            <table id="users-list-datatable" class="table table-sm">
                              <thead>
                              <tr>
                                <th class="position-relative" :style="{width:'30%'}">Name
                                </th>
                                <th class="position-relative" :style="{width:'30%'}">Content
                                </th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr v-for="(metaRow, index) in formatedMeta" :key="index">
                                <td>{{ metaRow.name }}</td>
                                <td>{{ metaRow.content }}</td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 mb-2">
                  <div class="d-flex justify-content-end">
                    <button @click="updateSingleScam" type="button" class="btn btn-primary update-todo px-4">Update
                      Scam
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="" data-toggle="modal" data-target="#metaInformationModal"></div>
        <MetaInformationModal :modal-usages-for="2"
                              modal-name="metaInformationModal"
                              :initial-meta-rows="formatedMeta"
                              :exclude-meta-names="[]"
                              @onMetaInformation="handleMetaInformation"/>
      </div>
    </template>
  </AppLayout>
</template>

<script>
// components
import AppLayout from '@/layouts/backEnd/AppLayout.vue'

// core packages
import {mapActions, mapGetters} from 'vuex';

// 3rd party packages
import VueMultiselect from 'vue-multiselect';
import {QuillEditor} from '@vueup/vue-quill';
import {DatePicker} from 'v-calendar';
// mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

// css
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import Authorization from "@/components/backEnd/mixins/Authorization";
import ToggleModal from "@/components/backEnd/mixins/ToggleModal";
import MetaInformationModal from "@/components/backEnd/MetaInformationModal.vue";

export default {
  components: {
    AppLayout,
    VueMultiselect,
    QuillEditor,
    DatePicker,
    MetaInformationModal
  },
  name: "ScamAlertEdit.vue",
  mixins: [ShowToastMessage, Loader, Authorization, ToggleModal],

  data() {
    return {
      rowSize: 1,
      isAccordionOpen: false,
      selectedMetaTag: [],
      content: [],
      metaTagOptions: [
        {value: 'title', name: 'Title', contentPlaceholder: 'Enter meta title', $isDisabled: false},
        {
          value: 'description',
          name: 'Description',
          contentPlaceholder: 'Enter meta description, but not more than 250 Characters.',
          $isDisabled: false
        },
        {
          value: 'keyword',
          name: 'Keyword',
          contentPlaceholder: 'Enter meta keyword. please, separated by comma',
          $isDisabled: false
        },
        {value: 'author', name: 'Author', contentPlaceholder: 'Enter meta author', $isDisabled: false},
      ],
      metaTagRows: [],
      tagPlaceholder: [],
      selectedTags: [],
      isPublished: true,
      getScamCategoriesParams: {
        status: 1,
      },

      putScamData: {
        post_category_id: '',
        title: '',
        body: '',
        is_featured: 1,
        status: 1,
        excerpt: '',
        published: true,
        published_date: '',
        tag_ids: []
      },

      existingScamData: {
        post_category_id: '',
        title: '',
        body: '',
        is_featured: 1,
        status: 1,
        excerpt: '',
        published: true,
        published_date: '',
        tag_ids: []
      },

      postBlogBlogTagStoreListData: {
        blog_id: null,
        blog_tag_id: null,
      },

      selectedIsFeatured: true,
      selectedStatus: true,

      errors: {
        post: {
          postCategoryId: '',
          postTagId: '',
          title: '',
          body: '',
          isFeatured: '',
          status: '',
          excerpt: '',
          isPublished: '',
          publishedDate: ''
        }
      },

      selectedScamCategory: {
        value: '',
        name: 'None'
      },
      metaJson: [],
      getScamParamObj: {
        with_relation: ['postCategory', 'tags', 'media'],
      },
      formatedMeta: [],

    }
  },
  watch: {
    selectedScamCategory(selectedScamCategory) {
      this.putScamData.post_category_id = selectedScamCategory.value;
    },
    isPublished(isPublished) {
      this.putScamData.published = isPublished === true ? 1 : 0;
    },

    selectedIsFeatured(selectedIsFeatured) {
      this.putScamData.is_featured = selectedIsFeatured === true ? 1 : 0;
    },

    selectedStatus(selectedStatus) {
      this.putScamData.status = selectedStatus === true ? 1 : 0;
    },
  },
  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
      scam: 'appScams/scam'
    }),
    redirectRouteName() {
      return this.can('scam-view-any') ? 'appScamAlertList' : 'appScamAlertEdit';
    },

    scamCategoryOptions() {
      let scamCategories = this.$store.getters['appScamCategories/scamCategories'].map((scamCategory) => {
        let id = scamCategory.id;
        let name = scamCategory.name;
        return {value: id, name: name};
      });
      return [{value: '', name: 'None'}, ...scamCategories];
    },

    tagOptions() {
      return this.$store.getters['appTags/tags'].map(tag => {
        return {
          value: tag.id,
          name: tag.name,
        };
      });
    },

    scamDataChange() {
      return (this.putScamData.title !== this.existingScamData.title) || (this.putScamData.description !== this.existingScamData.description) ||
          (this.putScamData.post_category_id !== this.existingScamData.post_category_id) || ((this.selectedStatus ? 'Active' : 'Inactive') !== this.existingScamData.status) ||
          ((this.selectedIsFeatured ? 'Yes' : 'No') !== this.existingScamData.is_featured);
    }
  },

  methods: {
    ...mapActions({
      getScam: 'appScams/getScam',
      putScam: 'appScams/putScam',
      getScamCategories: 'appScamCategories/getScamCategories',

      getTags: 'appTags/getTags',
      postBlogBlogTagStoreList: 'appBlogBlogTags/postBlogBlogTagStoreList',
      putBlogTag: 'appBlogTags/putBlogTag',
    }),
    addMetaTagRow() {
      if (this.metaTagRows.length === this.metaTagOptions.length) return;
      this.metaTagRows.push({selectedMeta: null, content: ''})
    },

    removeMetaTagRow(index) {
      const optionValue = this.metaTagRows[index].selectedMeta?.value;
      if (optionValue) {
        const optionIndex = this.metaTagOptions.findIndex(({value}) => value === optionValue);
        this.metaTagOptions[optionIndex] = {...this.metaTagOptions[optionIndex], $isDisabled: false};
      }
      this.metaTagRows.splice(index, 1);
    },

    updateMetaTagOption(index) {
      this.$nextTick(() => {
        // set placeholder from selected meta tag option
        this.tagPlaceholder[index] = this.metaTagRows[index].selectedMeta.contentPlaceholder;
        // get all meta tag rows value
        const currentValues = this.metaTagRows.map(row => row.selectedMeta?.value).filter(value => value);
        // set all meta option disable value false exclude currentValues
        this.metaTagOptions.forEach((row, index) => {
          this.metaTagOptions[index] = currentValues.includes(row.value) ? {
            ...this.metaTagOptions[index],
            $isDisabled: true
          } : {...this.metaTagOptions[index], $isDisabled: false};
        });
      });
    },

    toggleMeta() {
      this.isAccordionOpen = !this.isAccordionOpen;
    },
    async handleMetaInformation(metaRows) {
      this.putScamData.meta = JSON.stringify(metaRows);
      await this.updateSingleScam();
      this.formatedMeta = this.scam.meta = this.scam && this.scam.meta ? await JSON.parse(this.scam.meta) : [];
      await this.toggleModal('metaInformationModal');
    },

    async createTag(tag) {
      const data = {
        name: tag,
      }
      this.loader(true)
      await this.postTag(data).then(async response => {
        this.loader(false)
        if (response.status === 201) {
          this.selectedTags.push(this.tagOptions[0]);
          return;
        }
        if (response.message) {
          this.showToastMessage(response);
        }
      });
    },

    async getScamCategoryList() {
      await this.getScamCategories(this.getScamCategoriesParams);
    },

    async getTagList() {
      await this.getTags();
    },

    async updateSingleScam() {
      this.putScamData.tag_ids = this.selectedTags.map(({value}) => value);
      // Prepare data object for updating the scam
      let dataObj = {
        id: this.$route.params.id,
        data: {
          ...this.putScamData,
        },

      };

      // Check if there are changes in the scam data
      if (this.scamDataChange) {
        // Display loader while updating scam
        this.loader(true);
        // Update the scam using Vuex action putBlog
        await this.putScam(dataObj).then(async (response) => {
          // Hide loader after update
          this.loader(false);
          if (response.status === 200 || response.status === 201) {
            const toastObj = {
              message: 'Scam update successful.',
              type: 'success'
            };

            this.showToastMessage(toastObj);
          }
          // Handle and display errors if any
          this.errors.post.postCategoryId = response.errors && response.errors.scam_category_id ? response.errors.scam_category_id[0] : '';
          this.errors.post.postTagId = response.errors && response.errors.scam_tag_id ? response.errors.scam_tag_id[0] : '';
          this.errors.post.title = response.errors && response.errors.title ? response.errors.title[0] : '';
          this.errors.post.description = response.errors && response.errors.description ? response.errors.description[0] : '';
          this.errors.post.isFeatured = response.errors && response.errors.isFeatured ? response.errors.isFeatured[0] : '';
          this.errors.post.status = response.errors && response.errors.status ? response.errors.status[0] : '';

          if (response.message) {
            this.showToastMessage(response);
          }

        })
      }
      // Redirect to the specified route after updating the scam
      await this.$router.push({
        name: this.redirectRouteName,
      });
    },

    async getSingleScam() {
      // Prepare parameters object for fetching a single scam
      const paramObj = {
        id: this.$route.params.id,
        params: this.getScamParamObj,
      };
      //fetch a single scam
      await this.getScam(paramObj).then(async (response) => {
        if (response.status === 404) {
          this.commitNotFoundRouteStatus(true);
          return;
        }

        if (response.status === 200) {
          this.putScamData.post_category_id = this.scam.postCategory.id;
          this.putScamData.title = this.scam.title;
          this.putScamData.excerpt = this.scam.excerpt;
          this.putScamData.body = this.scam.body;
          this.putScamData.published_date = this.scam.publish_date;

          this.$refs.descriptionRef.setHTML(this.putScamData.body);
          this.selectedIsFeatured = (this.scam.is_featured === 'Yes');
          this.selectedStatus = (this.scam.status === 'Active');
          this.isPublished = (this.scam.published === 'Yes');

          this.putScamData.is_featured = this.selectedIsFeatured === true ? 1 : 0;
          this.putScamData.status = this.selectedStatus === true ? 1 : 0;
          this.putScamData.published = this.isPublished === true ? 1 : 0;

          this.existingScamData.post_category_id = this.putScamData.post_category_id;
          this.existingScamData.title = this.putScamData.title;
          this.existingScamData.excerpt = this.putScamData.excerpt;
          this.existingScamData.body = this.putScamData.body;
          this.existingScamData.is_featured = this.putScamData.is_featured;
          this.existingScamData.status = this.putScamData.status;
          this.existingScamData.published = this.putScamData.published;

          this.selectedScamCategory = this.scamCategoryOptions.find((item) => item.value === this.putScamData.post_category_id);

          this.selectedTags = this.scam.tags.map(tag => {
            return this.tagOptions.find((item) => item.value === tag.id);
          });
          // set existing meta json to array
          this.formatedMeta = this.scam.meta = this.scam && this.scam.meta ? await JSON.parse(this.scam.meta) : [];
          // check instance of array & min one item inside meta array
          this.metaTagRows = await (this.scam.meta && this.scam.meta instanceof Array && this.scam.meta.length > 0) ?
              this.scam.meta.map((singleMeta) => {
                return {
                  'selectedMeta': {
                    ...this.metaTagOptions.find((tagOption) => singleMeta.name === tagOption.value),
                    $isDisabled: true
                  },
                  'content': singleMeta.content
                }

              }) : [];
          this.isAccordionOpen = (this.scam.meta.length > 0);
          // get all selected meta name.
          const currentValues = this.metaTagRows.map(row => row.selectedMeta?.value).filter(value => value);
          // set all meta option disable value false exclude currentValues
          this.metaTagOptions.forEach((row, index) => {
            this.metaTagOptions[index] = currentValues.includes(row.value) ? {
              ...this.metaTagOptions[index],
              $isDisabled: true
            } : {...this.metaTagOptions[index], $isDisabled: false};
          });
        }

        this.showToastMessage(response);
      });
    },

  },


  async mounted() {
    this.loader(true);
    await this.getScamCategoryList();
    await this.getTagList();
    await this.getSingleScam();
    this.loader(false);
  },


}
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>

</style>